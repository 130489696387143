<template>
  <!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="dialogNuevaNomina.estatus"
    persistent
    max-width="1000"
  >
    <v-card>
    	<v-card-title primary-title>
    	  
    	</v-card-title>
    	<v-card-text>
    		<v-row>
    			<v-col cols="12" md="6">
    				<v-radio-group
				      v-model="opEscuela"
				      row
				    >
				      <v-radio
				        label="INBI"
				        :value="1"
				      ></v-radio>
				      <v-radio
				        label="FAST"
				        :value="2"
				      ></v-radio>
				    </v-radio-group>

		    		<v-autocomplete
		          filled
		          dense
		          clearable
		          v-model="id_ciclo"
		          :items="ciclos"
		          label="Selecciona ciclo actual"
		          persistent-hint
		          item-text="ciclo"
		          item-value="id_ciclo"
		        >
		        </v-autocomplete>

		        <v-textarea
		        	v-model="comentarios"
		        	filled
		        	dense
		        	label="Comentarios"
		        	rows="3"
		        >	
		        </v-textarea>
    			</v-col>
    		</v-row>

    		<v-btn color="primary" @click="dialog = true, verTabla = false"><v-icon>mdi-plus</v-icon> Partida</v-btn>

        <v-data-table
        	v-if="verTabla"
			    :headers="headers"
			    :items="detalles"
			    dense
			    class="elevation-0 mt-4"
			  >

			    <template v-slot:item.id_plantel="{ item }">
			      <span>{{ planteles.find( el=> el.id_plantel == item.id_plantel ).plantel }}</span>
			    </template>

			    <template v-slot:item.id_elemento_requisicion="{ item }">
			      <span>{{ elementos.find( el=> el.id_elemento_mantenimiento == item.id_elemento_requisicion ).elemento_mantenimiento }}</span>
			    </template>

			  </v-data-table>

    	</v-card-text>

    	<v-card-actions>
		    <v-btn dark block @click="dialogNuevaNomina.estatus = false" color="primary">Cerrar</v-btn>
    	</v-card-actions>
    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="350px"
    >
      <v-card>
        <v-card-title>
        	<v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text">
        	<v-row>
        		<v-col cols="12">
			    		<v-autocomplete
			    		  label="Elemento de requisición"
			          filled
			          dense
			          clearable
			          v-model="editedItem.id_elemento_requisicion"
			          :items="elementos"
			          persistent-hint
			          item-text="elemento_mantenimiento"
			          item-value="id_elemento_mantenimiento"
			        >
			        </v-autocomplete>

			    		<v-autocomplete
			          filled
			          dense
			          clearable
			          v-model="editedItem.id_plantel"
			          :items="planteles"
			          label="Selecciona plantel"
			          persistent-hint
			          item-text="plantel"
			          item-value="id_plantel"
			        >
			        </v-autocomplete>

			    		<v-text-field
			          filled
			    		  label="Concepto"
			          dense
			          v-model="editedItem.concepto"
			    		></v-text-field>

			    		<v-text-field
			          filled
			    		  label="Cantidad"
			          dense
			          type="number"
			          v-model="editedItem.cantidad"
			    		></v-text-field>

			    		<v-text-field
			          filled
			    		  label="Costo Unit."
			          dense
			          type="number"
			          v-model="editedItem.costo_unitario"
			    		></v-text-field>

			    		<v-text-field
			          filled
			    		  label="Costo total"
			          dense
			          readonly
			          v-model="editedItem.costo_total"
			    		></v-text-field>

	    			</v-col>
        	</v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn dark block @click="agregar()" color="primary">Agregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
  </v-dialog>
</template>


<script>
import axios from 'axios';

	import { mapGetters } from 'vuex'


	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'


	export default {
		props:[
			'dialogNuevaNomina'
	  ],

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Elemento Compra' , value: 'id_elemento_requisicion' },
        { text: 'Concepto'        , value: 'concepto'                },
        { text: 'Plantel'         , value: 'id_plantel'              },
        { text: 'Cantidad'        , value: 'cantidad'                },
        { text: 'Costo Unit.'     , value: 'costo_unitario'          },
        { text: 'Total'           , value: 'costo_total'             },
        { text: 'Actions'         , value: 'actions', sortable: false },
      ],

      ciclo: null,
      comentarios:'Pago servicio de las sucursales',

      opVisualizacion: 1,

      editedIndex: -1,

      editedItem: {
        id_elemento_requisicion: 0, 
		    id_plantel:              0, 
		    concepto:                'Pago servicio', 
		    cantidad:                1, 
		    costo_unitario:          0,
		    costo_total:             0,
		    id_empleado:             0, 
    		detalles:[]
      },

      defaultItem: {
        id_elemento_requisicion: 0, 
		    id_plantel:              0, 
		    concepto:                'Pago servicio', 
		    cantidad:                1, 
		    costo_unitario:          0,
		    costo_total:             0,
		    id_empleado:             0, 
    		detalles:[]
      },

      detalles:[],
      empleados:[],
      elementos:[],
      planteles:[],
      verTabla: true,

      id_usuario_solicita:'',
      id_sucursal_solicita:'',
      id_requisicion_compra_estatus:'',
      id_usuario_ultimo_cambio:'',

      id_usuario_compra: 28,
      id_ciclo:0,
      comentarios:'Pago servicio sucursal: ',
      idrequisicion_compra : null,
      ciclosInbi:[],
      ciclosFast:[],
      opEscuela:1
    }),

    computed: {
    	...mapGetters('login', ['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },

      ciclos(){
      	return this.opEscuela == 1 ? this.ciclosInbi : this.ciclosFast
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      "editedItem.cantidad"( value ){
      	if( value && this.editedItem.costo_unitario ){ this.editedItem.costo_total = value * this.editedItem.costo_unitario }
      },

      "editedItem.costo_unitario"( value ){
      	if( this.editedItem.cantidad && value ){ this.editedItem.costo_total = value * this.editedItem.cantidad }
      },
    },

    async created () {
    	this.id_usuario_solicita           = this.getdatosUsuario.iderp
      this.id_sucursal_solicita          = this.getdatosUsuario.id_plantel
      this.id_requisicion_compra_estatus = 5
      this.id_usuario_ultimo_cambio      = this.getdatosUsuario.iderp
      
      await this.getCiclosActivos()
      await this.getPlanteles()
      await this.getElementosCompras()
    },

    methods: {
      getCiclosActivos () {
        this.cargar = true

        return this.$http.get('get.ciclos.renta').then(response=>{
        	this.ciclosFast = response.data.ciclosRentaFast
        	this.ciclosInbi = response.data.ciclosRentaInbi
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    	getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getElementosCompras () {
      	this.cargar = true
        this.elementos = []
        return this.$http.get('consultar.elemtos.compra').then(response=>{
        	this.elementos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close(){
      	this.dialog = false
      	this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        this.verTabla = true
      },

      agregar(){
      	let detalles = []
	      this.detalles.push( this.editedItem )
      	detalles.push( this.editedItem )

	      let payload = []

	      let elementoTexto = this.elementos.find( el=> el.id_elemento_mantenimiento == this.editedItem.id_elemento_requisicion ).elemento_mantenimiento
      	
      	if( this.elementos.find( el=> el.id_elemento_mantenimiento == this.editedItem.id_elemento_requisicion ).automatico == 1 ){
	      	for( const i in this.ciclos ){
	      		payload.push({
		      		registro:                      this.idrequisicion_compra,
		      		id_usuario_solicita:           this.id_usuario_solicita,
				      id_sucursal_solicita:          this.id_sucursal_solicita,
				      id_requisicion_compra_estatus: this.id_requisicion_compra_estatus,
				      id_usuario_ultimo_cambio:      this.id_usuario_ultimo_cambio,

				      id_usuario_compra:             28,
				      tipo_requisicion:              3,
				      id_ciclo:                      this.ciclos[i].id_ciclo,
				      comentarios:                   'Pago de ' + elementoTexto + ' de: ' + this.planteles.find(el => el.id_plantel == this.editedItem.id_plantel).plantel ,
				      detalles
	      		})
	      	}
      	}else{
      		payload.push({
	      		registro:                      this.idrequisicion_compra,
	      		id_usuario_solicita:           this.id_usuario_solicita,
			      id_sucursal_solicita:          this.id_sucursal_solicita,
			      id_requisicion_compra_estatus: this.id_requisicion_compra_estatus,
			      id_usuario_ultimo_cambio:      this.id_usuario_ultimo_cambio,

			      id_usuario_compra:             28,
			      tipo_requisicion:              3,
			      id_ciclo:                      this.id_ciclo,
			      comentarios:                   'Pago de ' + elementoTexto + ' de: ' + this.planteles.find(el => el.id_plantel == this.editedItem.id_plantel).plantel ,
			      detalles
      		})
      	}
        
        return this.$http.post('generar.renta.sucursales', payload ).then(response=>{
        	this.idrequisicion_compra = response.data.idrequisicion_compra
        	this.cargar      = false
	      	// Agregarmos primero los datos
	      	this.dialog = false
	      	this.$nextTick(() => {
	          this.editedItem = Object.assign({}, this.defaultItem)
	          this.editedIndex = -1
	        })
	        this.verTabla = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      }
    },
  }
</script>